let toggleMenu = document.getElementsByClassName('js-toggle-menu');
let menu = document.getElementsByClassName('js-menu');

toggleMenu[0].addEventListener('click', function() {
  if (menu[0].classList.contains('d-block')) {
    toggleMenu[0].classList.remove('open');
    menu[0].classList.remove('d-block');
  } else {
    toggleMenu[0].classList.add('open');
    menu[0].classList.add('d-block');
  }
});
